import { Menu } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router';
import type { Contracts, Features, User } from '@/api_clients/models';
import { SquareButton } from '@/components/button';
import EqutumLogo from '@/images/equtum_logo.svg?react';
import EqutumLogoHorizontal from '@/images/equtum_logo_h.svg?react';
import IconBusinessTrip from '@/images/icons/icon_business_trip.svg?react';
import IconHorse from '@/images/icons/icon_horse.svg?react';
import IconInbox from '@/images/icons/icon_inbox.svg?react';
import IconReport from '@/images/icons/icon_lab_profile.svg?react';
import IconMenu from '@/images/icons/icon_menu.svg?react';
import IconOwners from '@/images/icons/icon_owners.svg?react';
import IconRecords from '@/images/icons/icon_records.svg?react';
import IconSetting from '@/images/icons/icon_setting.svg?react';

export const SideBarContents = ({
  me,
  ownersUrl,
  contracts,
  features,
  isCompact = false,
}: {
  me: User | undefined;
  ownersUrl: string;
  contracts: Contracts | undefined;
  features: Features | undefined;
  isCompact?: boolean;
}) => {
  const { pathname } = useLocation();
  const selected = pathname.split('/')?.[1];
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const MOBILE_WIDTH = 640;
  const DESKTOP_WIDTH = 1024;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_WIDTH);
      if (window.innerWidth < DESKTOP_WIDTH) {
        setIsHovered(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // smサイズ以下では常にフルサイズ表示（isCompactを無視）
  const effectiveCompact = isMobile ? false : isCompact;
  const showFullSidebar = !effectiveCompact || isHovered;

  return (
    <>
      <div
        className={`bg-dim-dark absolute top-0 left-0 z-30 h-full w-full duration-300 lg:hidden ${isOpen ? 'opacity-100' : 'pointer-events-none opacity-0'}`}
        onClick={() => setIsOpen(false)}
      />
      <div
        className={`bg-surface-base-dark absolute left-0 z-30 flex h-svh flex-col overflow-hidden transition-all duration-200 ease-in-out ${
          isOpen ? 'w-sidebar' : 'w-0'
        } md:relative ${effectiveCompact ? 'md:hover:w-sidebar md:w-16' : 'md:w-sidebar'}`}
        onMouseEnter={() => effectiveCompact && setIsHovered(true)}
        onMouseLeave={() => effectiveCompact && setIsHovered(false)}
      >
        <div
          className={`flex items-center justify-center ${effectiveCompact && !showFullSidebar ? 'w-16' : 'w-sidebar'} border-border h-[45px] border-b border-solid py-3`}
        >
          {effectiveCompact && !showFullSidebar ? (
            <EqutumLogo className="h-10 w-10 p-1" />
          ) : (
            <EqutumLogoHorizontal />
          )}
        </div>
        <div className="text-glyphs-normal flex w-full flex-1 flex-col justify-between px-3 pt-4 pb-6 text-base leading-6 font-semibold tracking-wider whitespace-nowrap">
          <div className="flex flex-col gap-2">
            <SidebarItem
              active={selected == 'horses' || selected == ''}
              icon={<IconHorse className="aspect-square h-6 w-6 shrink-0" />}
              label="所属馬一覧"
              setIsOpen={setIsOpen}
              showLabel={showFullSidebar}
              to="/"
            />
            {contracts?.hasOrmContract && (
              <>
                <SidebarItem
                  active={selected == 'owners'}
                  icon={<IconOwners className="aspect-square h-6 w-6 shrink-0" />}
                  label="馬主一覧"
                  setIsOpen={setIsOpen}
                  showLabel={showFullSidebar}
                  to="/owners"
                />
                <SidebarItem
                  active={selected == 'reports'}
                  icon={<IconReport className="aspect-square h-6 w-6 shrink-0" />}
                  label="レポート一覧"
                  setIsOpen={setIsOpen}
                  showLabel={showFullSidebar}
                  to="/reports"
                />
              </>
            )}
            {contracts?.hasHnContract && (
              <SidebarItem
                active={selected == 'horse_note_records'}
                icon={<IconRecords className="aspect-square h-6 w-6 shrink-0" />}
                label="日誌一覧"
                setIsOpen={setIsOpen}
                showLabel={showFullSidebar}
                to="/horse_note_records"
              />
            )}
            {features?.businessTrip && (
              <SidebarItem
                active={selected == 'business_trips'}
                icon={<IconBusinessTrip className="aspect-square h-6 w-6 shrink-0" />}
                label="出張管理"
                setIsOpen={setIsOpen}
                showLabel={showFullSidebar}
                to="/business_trips"
              />
            )}
            {me?.hasOwnerAccount && (
              <SidebarItem
                active={false}
                icon={<IconInbox className="aspect-square h-6 w-6 shrink-0" />}
                label="受信レポート"
                setIsOpen={setIsOpen}
                showLabel={showFullSidebar}
                to={ownersUrl}
              />
            )}
          </div>
          <div>
            <Menu position="top">
              <Menu.Target>
                <div
                  className={`text-glyphs-normal flex h-[38px] cursor-pointer items-center gap-[10px] rounded-lg px-[16px] py-[10px] duration-200 ${effectiveCompact && !showFullSidebar ? 'w-auto justify-center' : 'w-full'}`}
                >
                  <IconSetting className="aspect-square h-6 w-6 shrink-0" />
                  {showFullSidebar && <div className="flex-1">設定</div>}
                </div>
              </Menu.Target>
              <Menu.Dropdown style={{ width: '176px' }}>
                <Menu.Item onClick={() => navigate('/logout')}>ログアウト</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      </div>
      <div className="bg-surface-base text-glyphs-normal absolute z-20 flex h-[50px] w-full flex-wrap items-center justify-center gap-4 overflow-hidden px-4 duration-100 lg:hidden">
        <SquareButton
          className="absolute left-4"
          variant="transparent"
          onClick={() => setIsOpen(true)}
        >
          <IconMenu className="text-glyphs-normal aspect-square" />
        </SquareButton>
        <Link className="my-auto aspect-[3.19] w-40 shrink-0 object-contain" to="/">
          <EqutumLogoHorizontal />
        </Link>
      </div>
    </>
  );
};

const SidebarItem = ({
  to,
  icon,
  label,
  active,
  setIsOpen,
  showLabel = true,
}: {
  to: string;
  icon: React.ReactNode;
  label: string;
  active: boolean;
  setIsOpen: (isOpen: boolean) => void;
  showLabel?: boolean;
}) => {
  return (
    <Link prefetch="intent" to={to}>
      <div
        className={`flex gap-2.5 rounded-lg ${showLabel ? 'px-4' : 'justify-center px-2'} py-2.5 ${active ? 'bg-item-active text-active' : ''}`}
        onClick={() => setIsOpen(false)}
      >
        {icon}
        {showLabel && <div className="flex-1">{label}</div>}
      </div>
    </Link>
  );
};
